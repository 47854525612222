<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Variantes de material
            </div>

            <v-form :disabled="loading">
                <v-row>
                    <v-col cols="12" sm="7" md="5" lg="4" xl="3">
                        <MatVarFind 
                            class="mb-n7" 
                            ref="matVarFind" 
                            v-model="filtros.MatVarParent" 
                            label="Tipo"
                            :showParentsOnly="true"
                            @change="loadData">
                        </MatVarFind>
                    </v-col>
                    <v-col cols="12" sm="7" md="5" lg="4" xl="3">
                        <v-text-field
                            class="mb-n7"
                            outlined
                            dense
                            required
                            text
                            v-model="filtros.MatVarNom"
                            label="Nombre"
                            @change="loadData">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-form>

            <v-btn
                color="primary"
                class="mt-5 mb-3"
                @click="onClickNuevaVariante">
                Nueva
            </v-btn>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
                :server-items-length="totalDataRows"
                :loading="loading"
                @click:row="onClickRowEditarVariante"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1"
                dense>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.Opt="{ item }">
                    <v-btn
                        class="my-1" small
                        style="width:30px; min-width:30px;"
                        :disabled="loading"
                        @click.stop="selectParent(item)">
                        <v-icon>mdi-arrow-down-right</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import MatVarFind from "../components/MatVarFind.vue";
export default ({
    components: {
      MatVarFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            loading: true,
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 15},
            headers: [
                { text: 'Código', width: 75, sortable: false, value: 'MatVarCod' },
                { text: 'Nombre', width:200, sortable: false, value: 'MatVarNom' },
                { text: 'Observaciones', width: 300, sortable: false, value: 'MatVarObs' },
                { text: '', width: 50, sortable: false, groupable: false, value: 'Opt', align: 'end' }
            ],
            dtOptionsLoaded: false,
            filtros: {
                MatVarNom: '',
                MatVarParent: null
            }
        }
    },
    watch: {
      options: {
        handler () {
          // Evitamos la llamada a la API en la primera carga de las options
          if(!this.dtOptionsLoaded) {
            this.dtOptionsLoaded = true;
            return;
          }
          this.loadData();
        },
        deep: true
      },
      // filtros: {
      //   handler () {
      //     this.loadData();
      //   },
      //   deep: true
      // },
      loading: {
        handler() {
          if (this.loading) {
            this.$refs.matVarFind.$refs.MatVarFindCtrl.isMenuActive = false;
          }
        },
        deep: true
      }
    },
    mounted() {
      this.loadData();
    },
    methods: {
      getDataFromApi() {
        this.loading = true;

        var NumRegsPag = this.options.itemsPerPage;
        var NumPag = this.options.page;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_DATA_LIST_PAGINATED',
            NumRegsPag: NumRegsPag,
            NumPag: NumPag,
            EmpId: this.empId,
            MatVarNom: this.filtros.MatVarNom,
            MatVarIdParent: this.filtros.MatVarParent != null ? this.filtros.MatVarParent.MatVarId : null,
            ShowNoParentOnly: true
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/matvar", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data.EntsList;
            const total = result.data.EntsTotCount;

            setTimeout(() => {
                this.loading = false
                resolve({
                  items,
                  total
                })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar las variantes.');
            }
          });
        })
      },
      loadData() {
        this.getDataFromApi()
        .then(data => {
          this.dataRows = data.items;
          this.totalDataRows = data.total;
        });
      },
      selectParent(item) {
        this.filtros.MatVarParent = {
          MatVarId: item.MatVarId,
          MatVarNom: item.MatVarNom
        };
        this.loadData();
      },
      setParentData(data) {
        this.$store.state.MatVarParentData = data;
      },
      onClickNuevaVariante() {
        this.setParentData(this.filtros.MatVarParent);
        this.$router.push('/variantes-material-nueva/');
      },
      onClickRowEditarVariante(item) {
        this.setParentData(null);
        this.$router.push('/variantes-material-editar/' + item.MatVarId);
      }
    } 
})
</script>
